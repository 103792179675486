import { toast } from "react-toastify";

class ApiResponse {
  constructor(success, data, message, trace) {
    this.success = success;
    this.data = data;
    this.message = message;
    this.trace = trace;
  }
}

export const returnResponse = (response) => {
  switch (true) {
    case response.status >= 200 && response.status <= 300:
      return new ApiResponse(
        true,
        response.data,
        response.data.message || "Success",
        response.data.trace || []
      );
    case response.status === 400 || response.status === 412:
      return new ApiResponse(
        false,
        null,
        response.data.message,
        response.data.trace
      );
    case response.status >= 501 && response.status <= 503:
      return new ApiResponse(
        false,
        null,
        "Service Not Available At The Moment!",
        response.data.trace
      );
    case response.status === 504 || response.status === 524:
      return new ApiResponse(
        false,
        null,
        "Time Out Error, Try Again Later",
        response.data.trace
      );
    case response.status === 401 || response.status < 500:
      return new ApiResponse(
        false,
        null,
        response.data.message,
        response.data.trace
      );
    default:
      return new ApiResponse(
        false,
        null,
        response.data.message,
        response.data.trace
      );
  }
};

export const returnErrorApiResponse = (error, shouldToast = true) => {
  let err = error;
  let trace = [];

  if (error.response) {
    const { data } = error.response;

    if (Array.isArray(data) && data.length > 0) {
      err = data[0].message;
      trace = data.trace;
    } else if (data && data.message) {
      err = data.message;
      trace = data.trace;
    }
  }

  if (shouldToast) {
    toast.error(err);
  }

  return new ApiResponse(false, null, `${err}`, trace);
};














// =========================================================================================

// import { toast } from "react-toastify";

// /**
//  * @class
//  */
// export default class ApiResponce {
//   /**
//    * @param {Boolean} success
//    * @param {object} data
//    * @param {String} message
//    * @param {Array} trace
//    */
//   constructor(success, data, message, trace) {
//     this.success = success;
//     this.data = data;
//     this.message = message;
//     this.trace = trace;
//   }
// }
// /**
//  * @param {object} response
//  * @returns {ApiResponce} ApiResponce
//  */
// export const returnResponse = (response) => {
//   // console.log(response);

//   switch (response.status) {
//     case 200:
//     case 201:
//     case 202:
//     case 203:
//     case 204:
//     case response.status <= 300:
//       return new ApiResponce(
//         true,
//         response.data,
//         response.data.message ?? "Success",
//         response.data.trace ?? []
//       );
//     case 400:
//     case 412:
//       return new ApiResponce(
//         false,
//         null,
//         response.data.message,
//         response.data.trace
//       );
//     case 501:
//     case 502:
//     case 503:
//       return new ApiResponce(
//         false,
//         null,
//         "Service Not Available At The Moment!",
//         response.data.trace
//       );
//     case 504:
//     case 524:
//       return new ApiResponce(
//         false,
//         null,
//         "Time Out Error, Try Again Later",
//         response.data.trace
//       );
//     case 401:
//     case response.status < 500:
//       return new ApiResponce(
//         false,
//         null,
//         response.data.message,
//         response.data.trace
//       );
//     case response.status >= 500:
//       // console.log(response);
//       return new ApiResponce(
//         false,
//         null,
//         response.data.message,
//         response.data.trace
//       );
//     default:
//       return new ApiResponce(
//         false,
//         null,
//         response.data.message,
//         response.data.trace
//       );
//   }
// };

// export const returnErrorApiResponse = (error, shouldToast = true) => {

  
//   let err = error;
//   let trace = [];
//   if (error.response) {
//     if (error.response.data.length > 0) {
//       // err = error.response.data.message;
//       err = error.response.data[0].message;
//       trace = error.response.data.trace;
//       // console.log(error.response);
//     } else {
//       err = error.response.data.message;
//       trace = error.response.data.trace;
//       // console.log(error.response);
//       // console.log(error.response);
//     }
//   }
//   if (shouldToast) toast.error(err);
//   return new ApiResponce(false, null, `${err}`, trace);
// };
