import React from "react";

import { Route, Redirect } from "react-router-dom";
// import { connect } from "react-redux";
// import FallbackSuspense from "./fallback/FallbackPage";
import { authStore } from "../../store/zatand.store";

const PrivateRoute = ({
  isAuthLoading,
  isAuthenticated,
  active,
  role,
  component: Component,
  ...rest
}) => {
  const isLoggedIn = authStore((state) => state.isLoggedIn);
  return (
    <Route
      {...rest}
      render={
        (props) =>
          isLoggedIn === true ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        // isLoggedIn === true && isLoggedIn === null ? (
        //   <FallbackSuspense />
        // ) : isLoggedIn === false && isLoggedIn === false ? (
        //   <Redirect to="/login" />
        // ) : isLoggedIn === false && isLoggedIn === null ? (
        //   <Redirect to="/login" />
        // ) : active === false ? (
        //   <Redirect to="/deactivated" />
        // ) : (
        //   <Component {...props} />
        // )
      }
    />
  );
};

// const mapStateToProps = (state) => ({
//   isAuthenticated: state.auth.isLoggedIn,
//   isAuthLoading: state.ui.isLoading,
//   active: state.auth.isLoggedIn,
//   userData: state.auth.user,
// });

// export default connect(mapStateToProps, {})(PrivateRoute);
export default PrivateRoute;
