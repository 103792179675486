import { getAllInstiution } from "../../service/institution.service";
// import {  institutionStore } from "../zatand.store";

export const fetchallIntitution = async (institutionStore) => {
  try {
    let repson = await getAllInstiution();
    // console.log(repson.success);
    if (repson.success) {
      institutionStore.setInstitutionList(repson.data.content);
    }
  } catch (error) {
    // console.log(error);
  }
};
