import { QueryClient } from "react-query";

export const queryClientSettings = {
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      cacheTime: 1000 * 60 * 60, // 1 hour (default value)
      refetchInterval: 9.7e7,
      refetchIntervalInBackground: true,
      suspense: false,
      staleTime: 60000,
    },
    mutations: {
      retry: 1,
    },
  },
};

export const queryClient = new QueryClient(queryClientSettings);
