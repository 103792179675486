import React from "react";
import "./fallback.scss";
const FallbackPage = () => {
  return (
    <div className="preloader-container">
      <div id="preloader"></div>
    </div>
  );
};

export default FallbackPage;
