import { HELLO } from "./types";
import {
  login,
  signUpService,
  getCurrentUser,
} from "../../service/auth.service";
import { toast } from "react-toastify";
import { resetStore } from "../zatand.store";
import { setTokenHeader } from "../../service/core";
import { queryClient } from "../queryClient";
export const DEFULAT_USER = {
  id: 1,
  firstName: "firstName",
  lastName: "lastName",
  email: "alaniomotoshoq@gmail.com",
  phoneNumber: "987656789876",
  orcidNumber: "",
  dateOfBirth: "2000-05-06",
  trainingEvidence: "",
  avatar: "images/avatar.png",
  gender: "female",
  educationLevel: "Bachelor",
  institutionId: null,
  institution: {
    id: 1,
    institutionName: "institutionName1",
    country: "nigeria",
    localGovernmentName: "Alimosho",
    stateName: "Lagos",
    longitude: null,
    latitude: null,
    approvalStatus: 1,
    logoPath: null,
    contactNumber: "444444444444",
    email: "inst1@gmail.com",
    address: "address 22",
    institutionPage: null,
    createdAt: "2020-08-27",
    updatedAt: "2020-08-27",
  },
  active: 1,
  resendToken: null,
  roles: ["ROLE_SYSTEM"],
  createdAt: "2020-08-27",
  updatedAt: "2020-08-27",
  enabled: true,
  accountNonExpired: true,
  accountNonLocked: true,
  credentialsNonExpired: true,
  username: "alaniomotoshoq@gmail.com",
  authorities: [
    {
      authority: "ROLE_SYSTEM",
    },
  ],
  links: [
    {
      rel: "self",
      href: "http://localhost:8090/api/users/1",
    },
    {
      rel: "users",
      href: "http://localhost:8090/api/users",
    },
  ],
};
// console.log(DEFULAT_USER);

export const sayHell = (name) => async (dispatch) => {
  try {
    dispatch({ type: HELLO, payload: name });
  } catch (error) {
    throw new Error();
  }
};

export const logOutUser = async () => {
  resetStore();
  queryClient.resetQueries();
  queryClient.invalidateQueries();
  queryClient.cancelQueries();
  queryClient.clear();
};

export const loginUser = async (loginData, ui, auth) => {
  ui.toggleIsLoading();
  loginData.email = loginData.email.toLowerCase();
  let responce = await login(loginData);

  if (responce.success) {
    auth.setUser((state) => {
      state.token = "Bearer " + responce.data.token;
    });
    setTokenHeader();
    let responce2 = await getCurrentUser();
    if (responce2.success) {
      auth.setUser((state) => {
        state.isLoggedIn = responce2.success;
        state.user = responce2.data;
        // console.log(responce2.data);
      });
      window.location.reload();
    }
  }
  ui.toggleIsLoading();
};

export const signUpUser = async (signUpData, ui, auth) => {
  ui.toggleIsLoading();

  let responce = await signUpService(signUpData);
  if (responce.success) {
    // console.log(responce.data);
    toast.success(
      responce?.message ?? "Account has been created was a success"
    );
    await auth.setUser((state) => {
      // state.isLoggedIn = true;
      state.isLoggedIn = responce.success;
      state.user = responce.data;
      state.token = "";
    });
  } else {
    // toast.error(responce.message);
  }
  ui.toggleIsLoading();
};
