import React from "react";
// import { useQuery } from "react-query";
// import { getAllInstiutionsForRegistration } from "./service/institution.service";
// import AppKey from "./util/keys";
import { toast } from "react-toastify";

function App2() {
  window.addEventListener(
    "online",
    function (e) {
      toast.dismiss();
      toast.success("You're  back online", {
        position: "bottom-center",
      });
    },
    false
  );

  window.addEventListener(
    "offline",
    function (e) {
      toast.dismiss();
      toast.error("You're offline right now. Check your connection.", {
        position: "bottom-center",
      });
    },
    false
  );

  // useQuery(AppKey.ALLINSTITUTIONS, getAllInstiutionsForRegistration);

  return <></>;
}

export default App2;
