import React, { Suspense, lazy, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import FallbackPage from "./components/util/fallback/FallbackPage";
import Error404 from "./pages/404/error404";
import PrivateRoute from "./components/util/privateRoute";
import { ToastContainer, Slide } from "react-toastify";
import { fetchallIntitution } from "./store/actions/institution.action";
import { institutionStore } from "./store/zatand.store";
import { ReactQueryDevtools } from "react-query/devtools";
import { setTokenHeader } from "./service/core";
import { QueryClientProvider } from "react-query";
import ErrorBoundary from "./components/util/errorB";
import { queryClient } from "./store/queryClient";
import App2 from "./App2";
import RefreshApp from "./components/RefreshApp/RefreshApp";

const Home = lazy(() => import("./pages/home/home"));
const InstitutionPageHome = lazy(() =>
  import("./pages/home/InstitutionPageHome")
);
const InstitutionHome = lazy(() => import("./pages/home/InstitutionHome"));
const Login = lazy(() => import("./pages/auth/login/login"));
const ForgotPassword = lazy(() =>
  import("./pages/auth/forgot_password/ForgotPassword")
);
const ResetPassword = lazy(() =>
  import("./pages/auth/reset_password/ResetPassword")
);
const SignUp = lazy(() => import("./pages/auth/singup/signup"));
const ChangePassword = lazy(() =>
  import("./pages/auth/change_password/ChangePassword")
);
const Dashboard = lazy(() => import("./pages/dashboard/dashboard"));
const AccountActivation = lazy(() =>
  import("./pages/account_activation/accountActivation")
);

const App = () => {
  const inst = institutionStore();

  useEffect(() => {
    setTokenHeader();
    fetchallIntitution(inst);
  }, []);

  return (
    <div className="main-div">
      <QueryClientProvider client={queryClient}>
        <App2 />
        <ErrorBoundary>
          <ToastContainer
            transition={Slide}
            hideProgressBar
            position={"top-right"}
            pauseOnHover={false}
            limit={1}
          />
          <Suspense fallback={<FallbackPage />}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/forgotpassword" component={ForgotPassword} />
              <Route exact path="/password-reset" component={ResetPassword} />
              <Route exact path="/changepassword" component={ChangePassword} />
              <Route exact path="/institutions" component={InstitutionHome} />
              <Route
                exact
                path="/institutions/:id"
                render={(props) => {
                  return <InstitutionPageHome {...props} />;
                }}
              />
              <Route
                exact
                path="/activate/:token"
                component={AccountActivation}
              />
              <PrivateRoute path="/home" component={Dashboard} />
              <Route component={Error404} />
            </Switch>
          </Suspense>
        </ErrorBoundary>

        <ReactQueryDevtools initialIsOpen={false} />
        <RefreshApp />
      </QueryClientProvider>
    </div>
  );
};

export default App;

// ========================================================================

// import React, { Suspense, lazy, useEffect } from "react";
// import { Route, Switch } from "react-router-dom";
// import "./App.css";
// import "react-toastify/dist/ReactToastify.css";
// import FallbackPage from "./components/util/fallback/FallbackPage";
// import Error404 from "./pages/404/error404";
// import PrivateRoute from "./components/util/privateRoute";
// import { ToastContainer, Slide } from "react-toastify";
// import { fetchallIntitution } from "./store/actions/institution.action";
// import { institutionStore } from "./store/zatand.store";
// import { ReactQueryDevtools } from "react-query/devtools";
// import { setTokenHeader } from "./service/core";
// import { QueryClientProvider } from "react-query";
// import ErrorBoundary from "./components/util/errorB";
// import { queryClient } from "./store/queryClient";
// import "./service/institution.service";
// import App2 from "./App2";
// import RefreshApp from "./components/RefreshApp/RefreshApp";

// const Home = lazy(() => import("./pages/home/home"));
// const InstitutionPageHome = lazy(() =>
//   import("./pages/home/InstitutionPageHome")
// );
// const InstitutionHome = lazy(() => import("./pages/home/InstitutionHome"));
// const Login = lazy(() => import("./pages/auth/login/login"));
// const ForgotPassword = lazy(() =>
//   import("./pages/auth/forgot_password/ForgotPassword")
// );
// const ResetPassword = lazy(() =>
//   import("./pages/auth/reset_password/ResetPassword")
// );
// const SignUp = lazy(() => import("./pages/auth/singup/signup"));
// const ChangePassword = lazy(() =>
//   import("./pages/auth/change_password/ChangePassword")
// );
// const Dashboard = lazy(() => import("./pages/dashboard/dashboard"));
// const AccountActivation = lazy(() =>
//   import("./pages/account_activation/accountActivation")
// );

// const App = () => {
//   const inst = institutionStore();

//   setTokenHeader();
//   useEffect(() => {
//     fetchallIntitution(inst);
//   }, []);
//   return (
//     <div className="main-div">
//       <QueryClientProvider client={queryClient}>
//         <App2 />
//         <ErrorBoundary>
//           <Suspense fallback={<FallbackPage />}>
//             <ToastContainer
//               transition={Slide}
//               hideProgressBar
//               position={"top-right"}
//               pauseOnHover={false}
//               limit={1}
//             />
//             <Switch>
//               <Route exact path="/" component={Home} />
//               <Route exact path="/login" component={Login} />
//               <Route exact path="/signup" component={SignUp} />
//               <Route exact path="/forgotpassword" component={ForgotPassword} />
//               <Route exact path="/password-reset" component={ResetPassword} />
//               <Route exact path="/changepassword" component={ChangePassword} />
//               <Route exact path="/institutions" component={InstitutionHome} />
//               <Route
//                 exact
//                 path="/institutions/:id"
//                 render={(props) => {
//                   return <InstitutionPageHome {...props} />;
//                 }}
//               />
//               <Route
//                 exact
//                 path="/activate/:token"
//                 component={AccountActivation}
//               />
//               <PrivateRoute path="/home" component={Dashboard} />
//               <Route component={Error404} />
//             </Switch>
//           </Suspense>
//         </ErrorBoundary>

//         <ReactQueryDevtools initialIsOpen={false} />
//         <RefreshApp />
//       </QueryClientProvider>
//     </div>
//   );
// };

// export default App;
