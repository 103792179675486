import axios from "axios";
import { logOutUser } from "../store/actions/auth.actions";

// export const BASEURL = "https://reaps2.vhdo.org/api";
export const BASEURL = "https://reaps.com.ng/api";
// export const BASEURL = "https://471d-197-210-52-49.ngrok-free.app/api";

export const reapsAxiosWOHeader = axios.create();

export const setTokenHeader = () => {
  const data = JSON.parse(localStorage.getItem("reaps"));

  if (data?.auth) {
    axios.defaults.headers.common["Authorization"] = data.auth.token;

    axios.interceptors.request.use((config) => {
      const authHeader = axios.defaults.headers.common["Authorization"];
      if (authHeader !== "") {
        return config;
      }
    });

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          if (data.auth.allow400 === "true") {
            logOutUser();
          }
        }
        return Promise.reject(error);
      }
    );
  }
};

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// import axios from "axios";
// import { logOutUser } from "../store/actions/auth.actions";

// export const BASEURL = "https://reaps2.vhdo.org/api";
// // export const BASEURL = "https://reaps.com.ng/api";
// // export const BASEURL = "https://b29c-102-91-5-178.ngrok-free.app/api";

// // export const BASEURL = "http://localhost:8090/api";

// export const reapsAxiosWOHeader = axios.create();

// export const setTokenHeader = () => {
//   var data = JSON.parse(localStorage.getItem("reaps"));
//   if (data.auth) {
//     axios.defaults.headers.common["Authorization"] = data.auth.token;
//     axios.interceptors.request.use((res) => {
//       let authHeader = axios.defaults.headers.common["Authorization"];
//       if (authHeader !== "") return res;
//     });
//     axios.interceptors.response.use(
//       (res) => {
//         return res;
//       },
//       function (error) {
//         if (error.response) {
//           if (error.response.status === 401) {
//             try {
//               if (data.auth.allow400 === "true") {
//                 logOutUser();
//               }
//             } catch (e) {
//               throw new e();
//             }
//           }
//         }

//         return Promise.reject(error);
//       }
//     );
//   }
// };
