import React, { useState, useEffect } from "react";
import "./errorB.scss";

export default function ErrorBoundary(props) {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleComponentError = (error, errorInfo) => {
      // You can also log the error to an error reporting service
      console.log(error, errorInfo);
      setHasError(true);
    };

    window.addEventListener("error", handleComponentError);

    return () => {
      window.removeEventListener("error", handleComponentError);
    };
  }, []);

  if (hasError) {
    return (
      <div className="wrapper">
        <div className="container">
          <div className="errorBoundary">
            <h1>Something went wrong.</h1>
            <a className="goBack" href="/">
              <i className="fa fa-arrow-left"></i> Go back{" "}
            </a>
          </div>
        </div>
      </div>
    );
  }

  return props.children;
}

// =============================================================================

// import React from "react";
// import "./errorB.scss";

// export default class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   static getDerivedStateFromError(error) {
//     // Update state so the next render will show the fallback UI.
//     return { hasError: true };
//   }

//   componentDidCatch(error, errorInfo) {
//     // You can also log the error to an error reporting service
//     console.log(error, errorInfo);
//   }

//   render() {
//     if (this.state.hasError) {
//       // You can render any custom fallback UI
//       return (
//         <div className="wrapper">
//           <div className="container">
//             <div className="errorBoundary">
//               <h1>Something went wrong.</h1>
//               <a className="goBack" href="/">
//                 <i className="fa fa-arrow-left"></i> Go back{" "}
//               </a>
//             </div>
//           </div>
//         </div>
//       );
//     }

//     return this.props.children;
//   }
// }
