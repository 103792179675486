import axios from "axios";
import { BASEURL, reapsAxiosWOHeader } from "./core";
import { returnResponse, returnErrorApiResponse } from "./apiResponce.service";

/**
 * @returns {ApiResponce} ApiResponce
 * @description Retrieves all institutions.
 */
export const getAllInstiutionWithPage = async () => {
  try {
    const response = await reapsAxiosWOHeader.get(`${BASEURL}/institutions-with-page?size=100`);
    return returnResponse(response);
  } catch (error) {
    return returnErrorApiResponse(error);
  }
};
/**
 * @returns {ApiResponce} ApiResponce
 * @description Retrieves all institutions.
 */
export const getAllInstiution = async () => {
  try {
    const response = await reapsAxiosWOHeader.get(`${BASEURL}/institutions/all`);
    return returnResponse(response);
  } catch (error) {
    return returnErrorApiResponse(error);
  }
};

/**
 * @returns {ApiResponce} ApiResponce
 * @description Retrieves all active institutions for viewing.
 */
export const getAllActiveInstiutionsForView = async () => {
  try {
    const response = await axios.get(
      `${BASEURL}/institutions/approved?type=1&size=100`
    );
    return returnResponse(response);
  } catch (error) {
    return returnErrorApiResponse(error);
  }
};

/**
 * @returns {ApiResponce} ApiResponce
 * @description Retrieves all inactive institutions for viewing.
 */
export const getAllInActiveInstiutionsForView = async () => {
  try {
    const response = await axios.get(
      `${BASEURL}/institutions/approved?type=0&size=50`
    );
    return returnResponse(response);
  } catch (error) {
    return returnErrorApiResponse(error);
  }
};

/**
 * @returns {ApiResponce} ApiResponce
 * @description Retrieves all institutions with active payment.
 */
export const getAllInstiutionsWithActivePayment = async () => {
  try {
    const response = await reapsAxiosWOHeader.get(
      `${BASEURL}/institutions/payment_v1`
    );
    return returnResponse(response);
  } catch (error) {
    return returnErrorApiResponse(error);
  }
};

/**
 * @returns {ApiResponce} ApiResponce
 * @description Retrieves all institutions for registration.
 */
export const getAllInstiutionsForRegistration = async () => {
  try {
    const response = await reapsAxiosWOHeader.get(
      `${BASEURL}/institutions/active_v1?size=100`
    );
    return returnResponse(response);
  } catch (error) {
    return returnErrorApiResponse(error);
  }
};

/**
 * @returns {ApiResponce} ApiResponce
 * @description Retrieves all institutions for viewing.
 */
export const getAllInstiutionsForView = async () => {
  try {
    const response = await reapsAxiosWOHeader.get(
      `${BASEURL}/institutions/active_view?size=100`
    );
    return returnResponse(response);
  } catch (error) {
    return returnErrorApiResponse(error);
  }
};

/**
 * @param {object} formBody
 * @returns {ApiResponce} ApiResponce
 * @description Creates an institution.
 */
export const createInstitution = async (formBody) => {
  try {
    const response = await axios.post(`${BASEURL}/institutions`, formBody);
    return returnResponse(response);
  } catch (error) {
    return returnErrorApiResponse(error);
  }
};

/**
 * @param {string} id
 * @param {object} formBody
 * @returns {ApiResponce} ApiResponce
 * @description Updates an institution by ID.
 */
export const updateInstitution = async (id, formBody, token) => {
  try {
    const response = await axios.patch(
      `${BASEURL}/institutions/${id}`,
      formBody
    );
    return returnResponse(response);
  } catch (error) {
    return returnErrorApiResponse(error);
  }
};

/**
 * @param {string} id
 * @returns {ApiResponce} ApiResponce
 * @description Toggles the approval status of an institution by ID.
 */
export const toggleInstitutionApproval = async (id) => {
  try {
    const response = await axios.get(`${BASEURL}/institutions-toggle/${id}`);
    return returnResponse(response);
  } catch (error) {
    return returnErrorApiResponse(error);
  }
};

/**
 * @param {string} id
 * @param {string} token
 * @returns {ApiResponce} ApiResponce
 * @description Deletes an institution by ID.
 */
export const deleteInstitution = async (id, token) => {
  try {
    const response = await axios.delete(`${BASEURL}/institutions/${id}`, {
      headers: { Authorization: `${token}` },
    });
    return returnResponse(response);
  } catch (error) {
    return returnErrorApiResponse(error);
  }
};

/**
 * @returns {ApiResponce} ApiResponce
 * @description Retrieves the user's institution.
 */
export const getMyInstitution = async () => {
  try {
    const response = await axios.get(`${BASEURL}/institutions/me`);
    return returnResponse(response);
  } catch (error) {
    return returnErrorApiResponse(error);
  }
};


// =======================================================================================================

// import axios from "axios";
// import { BASEURL, reapsAxiosWOHeader } from "./core";
// import { returnResponse, returnErrorApiResponse } from "./apiResponce.service";

// /**
//  *
//  * @returns {ApiResponce} ApiResponce
//  */
// export const getAllInstiution = async () => {
//   try {
//     const response = await reapsAxiosWOHeader.get(
//       `${BASEURL}/institutions/all`
//       // `${BASEURL}/institutions-with-page?size=100`
//     );
//     return returnResponse(response);
//   } catch (error) {
//     return returnErrorApiResponse(error);
//   }
// };


// // /**
// //  *
// //  * @returns {ApiResponce} ApiResponce

// export const getAllActiveInstiutionsForView = async () => {
//   try {
//     const response = await axios.get(
//       `${BASEURL}/institutions/approved?type=1&size=100`
//     );
//     return returnResponse(response);
//   } catch (error) {
//     return returnErrorApiResponse(error);
//   }
// };
// export const getAllInActiveInstiutionsForView = async () => {
//   try {
//     const response = await axios.get(
//       `${BASEURL}/institutions/approved?type=0&size=50`
//     );
//     return returnResponse(response);
//   } catch (error) {
//     return returnErrorApiResponse(error);
//   }
// };
// /**
//  *@description Get all the Institution with active payment
//  * @returns {ApiResponce} ApiResponce
//  */
// export const getAllInstiutionsWithActivePayment = async () => {
//   try {
//     const response = await reapsAxiosWOHeader.get(
//       `${BASEURL}/institutions/payment_v1`
//     );
//     return returnResponse(response);
//   } catch (error) {
//     return returnErrorApiResponse(error);
//   }
// };

// /**
//  *@description Get all the Institution with active payment
//  * @returns {ApiResponce} ApiResponce
//  */
// export const getAllInstiutionsForRegistration = async () => {
//   try {
//     const response = await reapsAxiosWOHeader.get(
//       `${BASEURL}/institutions/active_v1?size=100`
//     );
//     return returnResponse(response);
//   } catch (error) {
//     return returnErrorApiResponse(error);
//   }
// };

// export const getAllInstiutionsForView = async () => {
//   try {
//     const response = await reapsAxiosWOHeader.get(
//       `${BASEURL}/institutions/active_view?size=100`
//     );
//     return returnResponse(response);
//   } catch (error) {
//     return returnErrorApiResponse(error);
//   }
// };

// /**
//  *
//  * @param {object} formBody
//  * @returns {ApiResponce} ApiResponce
//  */
// export const createInstitution = async (formBody) => {
//   try {
//     const response = await axios.post(`${BASEURL}/institutions`, formBody);
//     return returnResponse(response);
//   } catch (error) {
//     return returnErrorApiResponse(error);
//   }
// };

// /**
//  *
//  * @param {object} formBody
//  * @returns {ApiResponce} ApiResponce
//  */
// export const updateInstitution = async (id, formBody, token) => {
//   try {
//     const response = await axios.patch(
//       `${BASEURL}/institutions/${id}`,
//       formBody
//     );
//     return returnResponse(response);
//   } catch (error) {
//     return returnErrorApiResponse(error);
//   }
// };

// /**
//  *
//  * @param {object} formBody
//  * @returns {ApiResponce} ApiResponce
//  */
// export const toggleInstitutionApproval = async (id) => {
//   try {
//     const response = await axios.get(`${BASEURL}/institutions-toggle/${id}`);
//     return returnResponse(response);
//   } catch (error) {
//     return returnErrorApiResponse(error);
//   }
// };

// /**
//  *
//  * @param {object} formBody
//  * @returns {ApiResponce} ApiResponce
//  */
// export const deleteInstitution = async (id, token) => {
//   try {
//     const response = await axios.delete(`${BASEURL}/institutions/${id}`, {
//       headers: { Authorization: `${token}` },
//     });
//     return returnResponse(response);
//   } catch (error) {
//     return returnErrorApiResponse(error);
//   }
// };

// /**
//  *
//  * @param {object} formBody
//  * @returns {ApiResponce} ApiResponce
//  */
// export const getMyInstitution = async () => {
//   try {
//     const response = await axios.get(`${BASEURL}/institutions/me`);
//     return returnResponse(response);
//   } catch (error) {
//     return returnErrorApiResponse(error);
//   }
// };
