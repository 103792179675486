/* eslint-disable no-unused-vars */
import create from "zustand";
import produce from "immer";
import { AUTHSTORE } from "./storeNames";
import packageInfo from "../../package.json";
import { queryClient } from "./queryClient";
// interface UiStore{}

const version = packageInfo.version;

//store version controle
const isRunningInBrowser = () => typeof window !== "undefined";

let _grandStorePersist = JSON.parse(localStorage.getItem("reaps"));

if (_grandStorePersist === null) {
  localStorage.setItem("reaps", JSON.stringify({ version }));
  _grandStorePersist = { version };
}
if (version > _grandStorePersist.version) {
  //reset store
  localStorage.setItem("reaps", JSON.stringify({ version }));
}
const persist = (namespace, config) => (set, get, api) => {
  const state = config(
    (args) => {
      set(produce(args));
      if (isRunningInBrowser) {
        _grandStorePersist[namespace] = get();
        localStorage.reaps = JSON.stringify(_grandStorePersist);
      }
    },
    get,
    api
  );

  return {
    ...state,
    ...(isRunningInBrowser() && _grandStorePersist[namespace]),
  };
};

export const authStore = create(
  persist(AUTHSTORE, (set, get) => ({
    token: "Brear ",
    isLoggedIn: false,
    allow400: "",
    // isActivated: false,
    user: {
      name: "",
      firstName: "",
      lastName: "",
      role: [],
      address: "",
      dateOfBirth: "",
      email: "",
      phone: "",
      educationLevel: "",
      orcid: "",
      instiution: "",
      trainingEvidenceImageLink: "",
    },
    // setUser: (userdata) =>
    //   set((state) => ({ user: { ...state.user, ...userdata } })),
    setUser: (userdata) => set(produce(userdata)),
    toggleIsLoggedIn: () => set((state) => ({ isLoggedIn: !state.isLoggedIn })),
  }))
);

/**
 * UiStore
 */

export const uiStore = create((set, get) => ({
  isLoading: false,
  toggleIsLoading: () => set((state) => ({ isLoading: !state.isLoading })),
}));

/**
 * institution Store
 */
export const institutionStore = create(
  persist("institution", (set, get) => ({
    institutionList: [],
    setInstitutionList: (newList) =>
      set((state) => ({ institutionList: newList })),
  }))
);

/**
 * Specilization Store
 */
export const specializationStore = create(
  persist("Specilization", (set, get) => ({
    specilization: [],
    setSpecilizationList: (newList) =>
      set((state) => ({ specilization: newList })),
  }))
);

/**
 * Users Store
 */
export const usersStore = create((set, get) => ({
  usersList: [],
  setUsersList: (newList) => set((state) => ({ usersList: newList })),
}));

/**
 * Donor Store
 */
export const donorStore = create((set, get) => ({
  donorList: [],
  setDonorList: (newList) => set((state) => ({ donorList: newList })),
}));

/**
 * Ethical Request From Store
 */
export const ethicalRequestFormStore = create((set, get) => ({
  updateFormData: {},
  // updateFormData: rawUpdate,
  formData: {},

  // formData: {
  //   institutionPaymentTypeId: null, // number
  //   researchTitle: "title",
  //   hasCoPrincipalInvestigator: true,
  //   hasProjectSponsored: false,
  //   durationOfResearchInYears: 1,
  //   objectivesOfTheStudy: "",
  //   specialization: "",
  //   completedEthicsTraining: false,
  //   specimenWillBeShippedOut: false,
  //   donorId: [],
  //   institutionId: null, //number
  //   canEdit: 1,
  //   whichBestDescribeYou: "STUDENT",
  //   areYouInvestigatorOrLocalPrincipalInvestigator: true,
  //   institutionPaymentTypeId : null,
  //   institutionId: null,
  // },
  principals: [],
  cloneForm: {},
  //  "principal":[{
  //   	"firstName":"my name",
  //   	"lastName":"last name",
  //   	"email":"emailme@gmail.com",
  //   	"phoneNumber":"2345098671423"
  //   }]
  institutionPaymentTypeId: null,
  institutionId: null,
  lettersOfSupportFromCoInvestigators: [],
  files: {
    cv: null,
    supervisorAttestationStatement: null,
    sponsorAttestationLetter: null,
    coverLetter: null,
    languageSummary: null,
    evidenceOfCompletion: null,
    consentForm: null,
    remita: null,
    proposal: null,
    questionaire: null,
    materialsTransferAgreementForm: null,
  },
  setPrincipals: (principalData) =>
    set(
      produce((state) => {
        state.principals = principalData;
      })
    ),
  getformData: () => get().formData,
  getfiles: () => get().files,
  setEthicalRequestfiles: (filesdata, name) =>
    set(
      produce((state) => {
        state.files = { ...state.files, [name]: filesdata };
      })
    ),
  setEthicalRequestfilesV2: (filesdata) => set(produce(filesdata), true),
  setEthicalRequestFormStore: (ethicalRequestFormData) =>
    set(
      produce((state) => {
        state.formData = { ...state.formData, ...ethicalRequestFormData };
      }),
      true
    ),
}));
/**
 * Admin Users Store
 */
export const adminStore = create((set, get) => ({
  adminList: [],
  setAdminList: (newList) => set((state) => ({ adminList: newList })),
}));

export const dropdownToggleStore = create((set, get) => ({
  toggleDrop: false,
  settoggleDrop: () => set((state) => ({ toggleDrop: !state.toggleDrop })),
}));
export const useStore = create(
  persist("theme", (set, get) => ({
    theme: "light",
    setTheme: (theme) => set((state) => ({ theme: theme })),
  }))
);

export const resetStore = () => {
  adminStore.setState((state) => {
    state.adminList = [];
  });
  donorStore.setState((state) => {
    state.donorList = [];
  });
  institutionStore.setState((state) => {
    state.institutionList = [];
  });
  specializationStore.setState((state) => {
    state.specilization = [];
  });
  usersStore.setState((state) => {
    state.usersList = [];
  });
  adminStore.setState((state) => {
    state.adminList = [];
  });
  // SET THE AUTH TO FALSE
  let _auth = authStore.getState();
  // let st = {};
  _auth.setUser((st) => {
    st.isLoggedIn = false;
    st.user = {};
    st.token = "";
    st.allow400 = "";
  });
  queryClient.invalidateQueries();
  queryClient.clear();
  return null;
};
