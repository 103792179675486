import axios from "axios";
import { BASEURL, reapsAxiosWOHeader } from "./core";
import { returnResponse, returnErrorApiResponse } from "./apiResponce.service";

export const login = async (formBody) => {
  try {
    const response = await reapsAxiosWOHeader.post(`${BASEURL}/auth`, formBody);
    return returnResponse(response);
  } catch (error) {
    return returnErrorApiResponse(error);
  }
};

export const getCurrentUser = async () => {
  try {
    const response = await axios.get(`${BASEURL}/users/me_v1`);
    return returnResponse(response);
  } catch (error) {
    return returnErrorApiResponse(error);
  }
};

export const activateAccount = async (token) => {
  try {
    const response = await reapsAxiosWOHeader.post(
      `${BASEURL}/user/activate/${token}`
    );
    return returnResponse(response);
  } catch (error) {
    return returnErrorApiResponse(error);
  }
};

export const signUpService = async (formBody) => {
  try {
    const response = await reapsAxiosWOHeader.post(
      `${BASEURL}/users`,
      formBody
    );
    return returnResponse(response);
  } catch (error) {
    return returnErrorApiResponse(error);
  }
};

export const resetPassword = async (email) => {
  try {
    const response = await reapsAxiosWOHeader.post(
      `${BASEURL}/reset-password`,
      { email }
    );
    return returnResponse(response);
  } catch (error) {
    return returnErrorApiResponse(error);
  }
};

export const changePassword = async (passwordUpdate) => {
  try {
    const response = await reapsAxiosWOHeader.post(
      `${BASEURL}/handle-password-reset`,
      passwordUpdate
    );
    return returnResponse(response);
  } catch (error) {
    return returnErrorApiResponse(error);
  }
};

export const createUser = async (formBody) => {
  try {
    const response = await axios.post(`${BASEURL}/users/create-user`, formBody);
    return returnResponse(response);
  } catch (error) {
    return returnErrorApiResponse(error);
  }
};

export const updateUser = async (formBody, id) => {
  try {
    const response = await axios.put(`${BASEURL}/users/${id}`, formBody);
    return returnResponse(response);
  } catch (error) {
    return returnErrorApiResponse(error);
  }
};

// --------------------------------------------------------------------------------------------------------------------------

// import axios from "axios";
// // eslint-disable-next-line
// import ApiResponce, {
//   returnResponse,
//   returnErrorApiResponse,
// } from "./apiResponce.service";
// import { BASEURL, reapsAxiosWOHeader } from "./core";

// /**
//  *
//  * @param {object} formBody
//  * @returns {ApiResponce} ApiResponce
//  */
// export const login = async (formBody) => {
//   try {
//     const response = await reapsAxiosWOHeader.post(`${BASEURL}/auth`, formBody);
//     return returnResponse(response);
//   } catch (error) {
//     return returnErrorApiResponse(error);
//   }
// };

// /**
//  *
//  * @returns {ApiResponce} ApiResponce
//  */
// export const getCurrentUser = async () => {
//   try {
//     // const response = await axios.get(`${BASEURL}/users/me_v1`);
//     const response = await axios.get(`${BASEURL}/users/me_v1`);
//     return returnResponse(response);
//   } catch (error) {
//     return returnErrorApiResponse(error);
//   }
// };
// /**
//  *
//  * @param {String} tokenn
//  * @returns {ApiResponce} ApiResponce
//  */
// export const activateAccount = async (tokenn) => {
//   try {
//     const response = await reapsAxiosWOHeader.post(
//       `${BASEURL}/user/activate/${tokenn}`
//     );
//     // console.log(response.data);
//     return returnResponse(response);
//   } catch (error) {
//     // console.log(error);

//     return returnErrorApiResponse(error);
//   }
// };
// /**
//  *
//  * @param {object} formBody
//  * @returns {ApiResponce} ApiResponce
//  */
// export const signUpService = async (formBody) => {
//   try {
//     const response = await reapsAxiosWOHeader.post(
//       `${BASEURL}/users`,
//       formBody
//     );
//     return returnResponse(response);
//   } catch (error) {
//     return returnErrorApiResponse(error);
//   }
// };

// /**
//  *
//  * @param {String} email
//  * @returns {ApiResponce} ApiResponce
//  */
// export const resetPassword = async (email) => {
//   try {
//     const response = await reapsAxiosWOHeader.post(
//       `${BASEURL}/reset-password`,
//       { email }
//     );
//     return returnResponse(response);
//   } catch (error) {
//     return returnErrorApiResponse(error);
//   }
// };
// /**
//  *
//  * @param {String} passwordUpdate
//  * @returns {ApiResponce} ApiResponce
//  */
// export const changePassword = async (passwordUpdate) => {
//   try {
//     const response = await reapsAxiosWOHeader.post(
//       `${BASEURL}/handle-password-reset`,
//       passwordUpdate
//     );
//     return returnResponse(response);
//   } catch (error) {
//     return returnErrorApiResponse(error);
//   }
// };

// /**
//  *
//  * @param {object} formBody
//  * @returns {ApiResponce} ApiResponce
//  */
// export const createUser = async (formBody) => {
//   try {
//     const response = await axios.post(`${BASEURL}/users/create-user`, formBody);
//     return returnResponse(response);
//   } catch (error) {
//     return returnErrorApiResponse(error);
//   }
// };
